<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="home" href="/tabs/home">
          <ion-icon aria-hidden="true" :icon="home" />
          <ion-label>Hem</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="workorders" href="/tabs/workorders">
          <ion-icon aria-hidden="true" :icon="briefcase" />
          <ion-label>Sök uppdrag</ion-label>
          <div v-if="workOrdersNotifications.length > 0" class="absolute top-0 left-[50%]">
            <ion-badge color="danger" >{{ workOrdersNotifications.length }}</ion-badge>
          </div>
        </ion-tab-button>

        <ion-tab-button tab="my-workorders" href="/tabs/my-workorders">
          <ion-icon aria-hidden="true" :icon="time" />
          <ion-label>Mina uppdrag</ion-label>
          <div v-if="myWorkOrdersNotifications.length > 0" class="absolute top-0 left-[50%]">
            <ion-badge color="danger" >{{ myWorkOrdersNotifications.length }}</ion-badge>
          </div>
        </ion-tab-button>
        <ion-tab-button tab="messages" href="/tabs/messages">
          <ion-icon aria-hidden="true" :icon="mail" />
          <ion-label>Meddelanden</ion-label>
          <div v-if="messageNotifications.length > 0" class="absolute top-0 left-[50%]">
            <ion-badge color="danger" >{{ messageNotifications.length }}</ion-badge>
          </div>
        </ion-tab-button>
        <ion-tab-button tab="profile" href="/tabs/profile">
          <ion-icon aria-hidden="true" :icon="person" />
          <ion-label>Min profil</ion-label>
          <div v-if="payslipsNotifications.length > 0" class="absolute top-0 left-[50%]">
            <ion-badge color="danger" >{{ payslipsNotifications.length }}</ion-badge>
          </div>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script setup lang="ts">
import {IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet, IonBadge} from '@ionic/vue';
import { home, briefcase, time, mail, person } from 'ionicons/icons';
import {computed, onMounted} from "vue";
import useLocalStorage from "@/composables/useLocalStorage";
import { useUserStore } from "@/stores/user";
import {storeToRefs} from "pinia";

const userStore = useUserStore();
const { notifications } = storeToRefs(userStore);

onMounted(async () => {
  const storage = await useLocalStorage();
  const accessToken = storage.get('accountToken');
  if (accessToken !== null) {
    await userStore.shouldFetchUser();
  }
})

const messageNotifications = computed(() => {
  return notifications.value.filter(notification => notification?.data?.resource_type === 1);
})

const myWorkOrdersNotifications = computed(() => {
  return notifications.value.filter(notification => notification?.data?.resource_type === 4);
})

const payslipsNotifications = computed(() => {
  return notifications.value.filter(notification => notification?.data?.resource_type === 3);
})

const workOrdersNotifications = computed(() => {
  return notifications.value.filter(notification => notification?.data?.resource_type === 2);
})
</script>
