// longPressDirective.ts
import { DirectiveBinding } from 'vue';
import { Clipboard } from '@capacitor/clipboard';

export const vLongPress = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    let pressTimer: ReturnType<typeof setTimeout> | null = null;
    let startX = 0;
    let startY = 0;
    const moveThreshold = 10; // pixels of movement allowed before canceling long press

    const start = (e: TouchEvent | MouseEvent) => {
      if (e instanceof TouchEvent) {
        startX = e.touches[0].clientX;
        startY = e.touches[0].clientY;
      } else {
        startX = e.clientX;
        startY = e.clientY;
      }

      pressTimer = setTimeout(() => {
        // Only prevent default and enable selection after long press threshold
        e.preventDefault();

        // Enable text selection
        el.style.userSelect = 'text';
        el.style.webkitUserSelect = 'text';

        // Create range and selection
        const range = document.createRange();
        range.selectNodeContents(el);
        const selection = window.getSelection();
        if (selection) {
          selection.removeAllRanges();
          selection.addRange(range);
        }

        // Optional: Show a toast or feedback
        if (binding.value?.onSelect) {
          binding.value.onSelect();
        }
      }, 500);
    };

    const move = (e: TouchEvent | MouseEvent) => {
      if (!pressTimer) return;

      let currentX: number;
      let currentY: number;

      if (e instanceof TouchEvent) {
        currentX = e.touches[0].clientX;
        currentY = e.touches[0].clientY;
      } else {
        currentX = e.clientX;
        currentY = e.clientY;
      }

      // Calculate distance moved
      const distance = Math.sqrt(
        Math.pow(currentX - startX, 2) +
        Math.pow(currentY - startY, 2)
      );

      // If moved more than threshold, cancel the long press
      if (distance > moveThreshold) {
        cancel();
      }
    };

    const cancel = () => {
      if (pressTimer) {
        clearTimeout(pressTimer);
        pressTimer = null;
      }

      // Reset text selection state if no text is actually selected
      const selection = window.getSelection();
      if (selection && selection.toString().length === 0) {
        el.style.userSelect = 'none';
        el.style.webkitUserSelect = 'none';
      }
    };

    // Handle copy event
    el.addEventListener('copy', async (e) => {
      const selectedText = window.getSelection()?.toString();
      if (selectedText) {
        await Clipboard.write({
          string: selectedText.trim()
        });

        // Optional: Show feedback on copy
        if (binding.value?.onCopy) {
          binding.value.onCopy();
        }

        // Reset selection after copy
        window.getSelection()?.removeAllRanges();
        el.style.userSelect = 'none';
        el.style.webkitUserSelect = 'none';
      }
    });

    // Touch events
    el.addEventListener('touchstart', start, { passive: true });
    el.addEventListener('touchmove', move, { passive: true });
    el.addEventListener('touchend', cancel);

    // Mouse events
    el.addEventListener('mousedown', start);
    el.addEventListener('mousemove', move);
    el.addEventListener('mouseup', cancel);
    el.addEventListener('mouseleave', cancel);
  }
};
