<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script setup lang="ts">
import {IonApp, IonRouterOutlet, isPlatform, useBackButton, useIonRouter} from '@ionic/vue';
import {onMounted} from "vue";
import {ActionPerformed, PushNotifications, PushNotificationSchema, Token} from "@capacitor/push-notifications";
import {useUserStore} from "@/stores/user";
import useNotificationCentre from "@/composables/useNotificationCentre";
import {App, AppState, StateChangeListener} from '@capacitor/app';

import {APIActionPerformed, APINotification} from "@/constants/pushNotificationTypes";
import {Keyboard} from "@capacitor/keyboard";
import {Capacitor} from "@capacitor/core";

const userStore = useUserStore();

const ionRouter = useIonRouter();
useBackButton(-1, () => {
  if (!ionRouter.canGoBack()) {
    App.exitApp();
  }
});

const setPushNotifications = async () => {
  const notificationCentre = await useNotificationCentre();

  await PushNotifications.requestPermissions().then(async (result) => {
    if (result.receive === 'granted') {
      // Register with Apple / Google to receive push via APNS/FCM
      await PushNotifications.register();

    } else {
      userStore.setNotificationsBlocked(true);
    }
  });
  // On success, we should be able to receive notifications
  await PushNotifications.addListener('registration', (token: Token) => {
    userStore.savePushToken(token.value);
  });

  // Some issue with our setup and push will not work
  await PushNotifications.addListener('registrationError', (error: any) => {
    alert('Error on registration: ' + JSON.stringify(error));
  });

  // Show us the notification payload if the app is open on our device
  await PushNotifications.addListener('pushNotificationReceived', (notification: APINotification) => {
    notificationCentre.handleNotification(notification, true);
  });

  // Method called when tapping on a notification
  await PushNotifications.addListener('pushNotificationActionPerformed', (notification: APIActionPerformed) => {
    notificationCentre.handleNotification(notification.notification, false);
  });

  await App.addListener('appStateChange', async(state: AppState) => {
    if (state.isActive) {
      await userStore.getPushNotifications();
    }
  });
}

onMounted(() => {
  if (Capacitor.isNativePlatform()) {
    setPushNotifications();
  }
  if (Capacitor.isNativePlatform() && isPlatform('ios')) {
    Keyboard.setAccessoryBarVisible({isVisible: true});
  }
});

</script>
