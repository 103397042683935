import { defineStore } from 'pinia';
import useApi from "@/composables/useApi";
import qs from 'qs';
import {Payslip, PayslipResponse} from "@/constants/payslipTypes";

export const usePayslipsStore = defineStore('payslipsStore', {
  state: () => ({
    payslips: [] as Payslip[],
    currentPage: 1,
    totalPages: 1,
    loading: false,
  }),
  actions: {
    async fetchPayslips(page = 1) {
      const { apiGet } = useApi();
      this.loading = true;
      const params = {
        page: page || 1,
        per_page: 12,
      };

      const query = qs.stringify(params);

      const res = await apiGet<PayslipResponse>(`/employee/me/payslips?${query}`);
      if (page === 1) {
        this.payslips = res.result.data;
      } else {
        this.payslips = [...this.payslips, ...res.result.data];
      }
      this.currentPage = res.result.current_page;
      this.totalPages = res.result.last_page;
      this.loading = false;
    },
    async nextPage() {
      if (this.currentPage < this.totalPages) {
        await this.fetchPayslips(this.currentPage + 1);
      }
    },
    async prevPage() {
      if (this.currentPage > 1) {
        await this.fetchPayslips(this.currentPage - 1);
      }
    },
  },
  getters: {
    lastPayslip() {
      if (this.payslips.length === 0) {
        return null;
      }
      return this.payslips[0];
    },
    hasNextPage: (state) => () => {
      return state.currentPage < state.totalPages;
    }
  }
});
